const AuthorLabels = [
  'Web Developer.',
  'Designer.',
  'Writer.',
  '<a class="styled-link" href="https://cs.unc.edu/" target="_blank" rel="noopener noreferrer">UNC CS</a> grad.',
  'Aspiring Pastry Chef.',
  '...<i>Amateur</i> Pastry Chef.',
  'Just a guy trying to write a little more on the web.',
  'Stephen Yan.',
];

export default AuthorLabels;
