import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import ReactCrossword from '@jaredreisinger/react-crossword';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

const Commands = styled.div`
  display: flex;
  justify-content: center;
  gap: 1em;
  margin: 0 8em;
  padding-top: 2em;

  @media (max-width: ${(p) => p.theme.screen.sm}) {
    margin: 1em 0;
  }
`;

const Command = styled.button`
  width: 100%;
  height: 3.5em;
  background-color: transparent;
  color: ${(p) => p.theme.color.font.regular};
  box-shadow: inset 0 0 0 2px ${(p) => p.theme.color.font.regular};
  border-radius: 4px;
  font-family: ${(p) => p.theme.font.markdown};
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.25em;
`;

const CrosswordMessageBlock = styled.div`
  margin: 2em 8em;
  gap: 2em;
  @media (max-width: ${(p) => p.theme.screen.sm}) {
    margin: 1em 0;
  }
`;

const CrosswordWrapper = styled.div`
  display: flex;
  gap: 1em;
  font-family: ${(p) => p.theme.font.markdown};

  .header {
    font-family: ${(p) => p.theme.font.primary};
  }

  .crossword.grid {
    flex: 1;
  }

  .clues {
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: 1em;
    padding: 0 !important;

    @media (max-width: ${(p) => p.theme.screen.sm}) {
      flex-direction: column;
    }
  }

  .crossword.correct {
    rect {
      stroke: rgb(100, 200, 100) !important;
    }
    svg > rect {
      fill: rgb(100, 200, 100) !important;
    }
    text {
      fill: rgb(100, 200, 100) !important;
    }
  }

  .clue.correct {
    ::before {
      content: '\u2713'; /* a.k.a. checkmark: ✓ */
      display: inline-block;
      text-decoration: none;
      color: rgb(100, 200, 100);
      margin-right: 0.25em;
    }
    text-decoration: line-through;
    color: rgb(130, 130, 130);
  }

  /* Target focused clue */
  .clue[class*='-'] {
    color: rgb(130, 130, 130);
  }
`;

const StyledConfetti = styled(Confetti)`
  position: fixed !important;
`;

const Crossword = ({ data }) => {
  const crossword = useRef(null);

  const [solved, setSolved] = useState(false);

  const fillAllAnswers = () => crossword.current?.fillAllAnswers();

  const reset = () => {
    crossword.current?.reset();
    setSolved(false);
  };

  const onCrosswordCorrect = () => setSolved(true);

  const { width, height } = useWindowSize();

  return (
    <>
      {solved && <StyledConfetti width={width} height={height} />}

      <Commands>
        <Command onClick={reset}>Reset</Command>
        <Command onClick={fillAllAnswers}>Give Up (loser)</Command>
      </Commands>

      <CrosswordMessageBlock>
        <CrosswordWrapper>
          <ReactCrossword
            ref={crossword}
            data={data}
            onCrosswordCorrect={onCrosswordCorrect}
          />
        </CrosswordWrapper>
      </CrosswordMessageBlock>
    </>
  );
};

export default Crossword;
