import React from 'react';
import styled, { css } from 'styled-components';

const Hamburger = ({ menuOpen }) => (
  <HamburgerWrapper>
    <InnerHamburger className={menuOpen && 'menu-open'} />
  </HamburgerWrapper>
);

const MenuTransitionStyle = css`
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
`;

const HamburgerWrapper = styled.span`
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  filter: invert(${(props) => (props.theme.invertImages ? 1 : 0)});
`;
const InnerHamburger = styled.span`
  display: block;
  width: 10px;
  height: 2px;
  background-color: ${(p) => p.theme.color.font.regular};
  border-radius: 2px;
  position: absolute;
  top: 2px;

  ${MenuTransitionStyle}

  &.menu-open {
    width: 16px;
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }

  &:before {
    content: '';
    display: block;
    width: 16px;
    height: 2px;
    border-radius: 2px;
    position: absolute;

    background-color: ${(p) => p.theme.color.font.regular};
    top: 6px;

    ${MenuTransitionStyle}
    transition-property: transform, opacity;
  }

  &.menu-open:before {
    transform: rotate(-45deg) translate3d(0, 0, 0);
    opacity: 0;
  }

  &:after {
    content: '';
    display: block;

    width: 10px;
    height: 2px;
    border-radius: 2px;
    position: absolute;
    right: -6px;

    background-color: ${(p) => p.theme.color.font.regular};
    top: 12px;
    bottom: -10px;

    ${MenuTransitionStyle}
  }

  &.menu-open:after {
    width: 16px;
    right: unset;
    transform: translate3d(0, -12px, 0) rotate(-90deg);
  }
`;

export default Hamburger;
