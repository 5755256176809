import divider from '@static/icons/divide.svg';

const baseTheme = {
  font: {
    primary: `'DM Serif Display',serif,-apple-system`,
    secondary: `'Playfair Display',serif,-apple-system`,
    markdown: `"SF Pro Display", "-apple-system", "BlinkMacSystemFont", "San Francisco", "Helvetica Neue", "Helvetica", "Ubuntu", "Roboto", "Noto", "Segoe UI", "Arial", sans-serif`,
  },
  font_size: {
    xsmall: 'font-size: 16px; line-height:24px',
    small: 'font-size: 20px; line-height: 30px',
    regular: 'font-size: 24px; line-height: 32px',
    large: 'font-size: 30px; line-height: 40px',
    larger: 'font-size: 36px; line-height: 48px',
    xlarge: 'font-size: 48px; line-height: 56px',
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
};

export const lightTheme = {
  ...baseTheme,
  invertImages: false,
  color: {
    white: {
      regular: '#FFFFFF',
      dark: '#F6F6F6',
    },
    black: {
      lighter: '#ABA8AF',
      light: '#564F62',
      regular: '#211E26',
    },
    font: {
      regular: '#211E26',
      dim: '#ABA8AF',
      dimmer: '#564F62',
      highlight: 'rgba(255, 255, 0, 0.3)',
      link: '#6166DC',
      error: '#d73a49',
    },
    trackline: 'rgba(0, 0, 0, .3)',
    form: '#E9E9E9',
    secondary: '#F6F6F6',
    primary: '#E8F1FB', // '#DCEAF9', // '#8DBAED',
  },
  divider,
};

export const darkTheme = {
  ...baseTheme,
  isDark: true,
  invertImages: true,
  color: {
    white: {
      regular: '#FFFFFF',
      dark: '#F6F6F6',
    },
    black: {
      lighter: '#ABA8AF',
      light: '#564F62',
      regular: '#211E26',
    },
    font: {
      regular: '#FFFFFF',
      dim: '#F6F6F6',
      dimmer: '#DDDDDD',
      highlight: 'rgba(255, 255, 0, 0.5)',
      link: '#fbf9c9',
      error: '#d73a49',
    },
    form: '#211E26',
    trackline: 'rgba(255, 255, 255, .3)',
    secondary: '#17151b', // #13294B
    primary: '#211E26', // #211E26 // #0f203b
  },
  divider,
};
