/**
 * Write-only the password as cookie
 */
import React from 'react';
import { Section, Container } from '@components/global';
import PasswordForm from './PasswordForm';
import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Footer from '@sections/Footer';

const PasswordProtect = () => {
  return (
    <Layout>
      <Navbar hideMenu={true} />
      <Section>
        <Container>
          <h1>What's the password, bucko?</h1>
          <PasswordForm />
        </Container>
      </Section>
      <Footer />
    </Layout>
  );
};

export default PasswordProtect;
