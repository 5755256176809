const Socials = [
  {
    icon: 'github',
    link: 'https://github.com/sjyan',
  },
  {
    icon: 'gitlab',
    link: 'https://gitlab.com/sjyan',
  },
  {
    icon: 'instagram',
    link: 'https://instagram.com/steviejyan',
  },
];

export default Socials;
