import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FortuneApi } from '@config';

const Fortune = () => {
  const [quote, setQuote] = useState('');

  useEffect(() => {
    let mounted = true;
    const fetchQuote = async () => {
      const fortune = await fetch(FortuneApi);
      if (mounted) setQuote(await fortune.json());
    };

    fetchQuote();
    return () => {
      mounted = false;
    };
  }, []);

  return <Quote>{quote}</Quote>;
};

const Quote = styled.pre`
  margin: 0 auto 35px;
  max-width: 680px;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: justify;

  @media (max-width: ${(p) => p.theme.screen.lg}) {
    max-width: 507px;
  }

  @media (max-width: ${(p) => p.theme.screen.md}) {
    max-width: 486px;
    margin: 0 auto 25px;
  }

  @media (max-width: ${(p) => p.theme.screen.md}) {
    max-width: 486px;
    margin: 0 auto 25px;
  }

  @media (max-width: ${(p) => p.theme.screen.sm}) {
    padding: 0 20px;
  }
`;

export default Fortune;
