import React, { Component } from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import DarkMode from './DarkMode/DarkMode';
import Hamburger from './Hamburger/Hamburger';

import { Container } from '@components/global';
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  MenuButton,
  Mobile,
} from './style';

const NAV_ITEMS = ['About', 'Writing', 'Contact'];

class Navbar extends Component {
  state = {
    mobileMenuOpen: false,
  };

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      mobileMenuOpen: !prevState.mobileMenuOpen,
    }));
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  getNavAnchorLink = (item) => (
    <AnchorLink href={`#${item.toLowerCase()}`} onClick={this.closeMobileMenu}>
      {item}
    </AnchorLink>
  );

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map((item) => item.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map((navItem) => (
          <NavItem mobile={mobile} key={navItem}>
            {this.getNavAnchorLink(navItem)}
          </NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  );

  render() {
    const { mobileMenuOpen } = this.state;
    const { hideMenu } = this.props;

    return (
      <Nav {...this.props}>
        <StyledContainer>
          <Brand>
            <Link to={'/'} className="unstyled-link">
              Stephen Yan
            </Link>
          </Brand>
          <DarkMode></DarkMode>
          {!hideMenu && (
            <Mobile>
              <MenuButton open={mobileMenuOpen} onClick={this.toggleMobileMenu}>
                <Hamburger menuOpen={mobileMenuOpen} />
              </MenuButton>
            </Mobile>
          )}
          {!hideMenu && <Mobile hide>{this.getNavList({})}</Mobile>}
        </StyledContainer>
        <Mobile>
          <MobileMenu open={mobileMenuOpen}>
            <Container>{this.getNavList({ mobile: true })}</Container>
          </MobileMenu>
        </Mobile>
      </Nav>
    );
  }
}

export default Navbar;
