import styled, { css } from 'styled-components';
import ActionStyles from '@styles/ActionStyles';

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  ${(p) => p.alignCenter && `align-items: center;`}
  margin: ${(p) => p.margin * 1}px 0;

  .field {
    flex-basis: 100%;
    margin: 0 0 ${(p) => p.margin * 1}px 0;

    &.half {
      flex-basis: 50%;
      padding: 0 0 0 ${(p) => p.margin * 1 * 0.5}px;

      &.first {
        padding: 0 ${(p) => p.margin * 1 * 0.5}px 0 0;
      }
    }
  }

  @media (max-width: ${(p) => p.theme.screen.md}) {
    .field {
      margin: 0 0 ${(p) => p.margin * 0.75}px 0;

      &.half {
        padding: 0 0 0 ${(p) => p.margin * 0.75 * 0.5}px;

        &.first {
          padding: 0 ${(p) => p.margin * 0.75 * 0.5}px 0 0;
        }
      }
    }
  }

  @media (max-width: ${(p) => p.theme.screen.sm}) {
    .field {
      &.half {
        flex-basis: 100%;
        padding: 0;

        &.first {
          padding: 0;
        }
      }
    }
  }
`;

export const Label = styled.label`
  font-family: ${(p) => p.theme.font.markdown};
  color: ${(p) => p.theme.color.font.regular};
  display: block;
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 0.25em;
  margin: 0 0 ${(p) => p.margin * 0.5}px 0;
  text-transform: uppercase;
`;

export const Actions = styled.ul`
  cursor: default;
  list-style: none;
  padding-left: 0;
`;

export const ActionItem = styled.li`
  display: inline-block;
  padding: 0 1em 0 0;
  vertical-align: middle;
  &:last-child {
    padding-right: 0;
  }
`;

export const Submit = styled.input`
  ${ActionStyles};
  background-color: ${(p) => p.theme.color.font.regular};
  box-shadow: none;
  color: ${(p) => p.theme.color.secondary};
`;

const InputStyles = css`
  background: ${(p) => p.theme.color.form};
  font-family: ${(p) => p.theme.font.markdown};
  border: none;
  border-radius: 5px;
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1em;
  text-decoration: none;
  width: 100%;
  resize: vertical;
  transition: box-shadow 0.3s ease-out;

  &:invalid {
    box-shadow: none;
  }

  &:focus {
    border-color: ${(p) => p.theme.color.font.regular};
    box-shadow: 0 0 0 1px ${(p) => p.theme.color.font.dim};
    transition: box-shadow 0.2s ease-out;
  }
`;

export const Input = styled.input`
  ${InputStyles};
  height: ${(p) => p.height}px;
  box-shadow: 0 0 0 1px
    ${(p) => (p.error ? p.theme.color.font.error : 'transparent')};
`;

export const Textarea = styled.textarea`
  ${InputStyles};
  padding: 0.75em 1em;
  box-shadow: 0 0 0 1px
    ${(p) => (p.error ? p.theme.color.font.error : 'transparent')};
`;

export const Clear = styled.input`
  ${ActionStyles};
`;

export const FieldError = styled.span`
  display: block;
  font-family: ${(p) => p.theme.font.markdown};
  ${(p) => p.theme.font_size.xsmall};
  color: ${(p) => p.theme.color.font.error};
  margin-top: 5px;
`;

export const Reset = styled.button`
  ${ActionStyles};
`;

export const Paragraph = styled.p`
  margin: 20px 0;
`;

export default Form;
