import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';
import RandomGenerator from '@util/RandomGenerator';

import { Socials } from '@config';
import * as Icons from '@static/icons';

const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        artImages: allFile(filter: { sourceInstanceName: { eq: "art" } }) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(
                  width: 960
                  placeholder: TRACED_SVG
                  layout: CONSTRAINED
                )
              }
              name
            }
          }
        }
      }
    `}
    render={(data) => {
      const { artImages } = data;
      const SOCIAL = Socials.map((s) => ({
        icon: Icons[s.icon],
        link: s.link,
      }));
      const { edges } = artImages;
      const randomPosition = RandomGenerator(0, edges.length - 1);
      const randomArtImage = edges[randomPosition].node;

      return (
        <React.Fragment>
          <Art>
            <GatsbyImage
              image={randomArtImage.childImageSharp.gatsbyImageData}
              alt={randomArtImage.name}
              style={{ width: 480, maxWidth: '100%', marginBottom: -16 }}
            />
          </Art>
          <FooterWrapper>
            <StyledContainer>
              <Copyright>
                <h2>
                  <Link to={'/'} className="unstyled-link">
                    Stephen Yan
                  </Link>
                </h2>
                <FooterText>
                  Illustrations by
                  {` `}
                  <ExternalLink href="https://twitter.com/diana_valeanu">
                    @diana_valeanu
                  </ExternalLink>
                </FooterText>
              </Copyright>
              <SocialIcons>
                {SOCIAL.map(({ icon, link }) => (
                  <ExternalLink key={link} href={link}>
                    <img src={icon} alt="link" />
                  </ExternalLink>
                ))}
              </SocialIcons>
            </StyledContainer>
          </FooterWrapper>
        </React.Fragment>
      );
    }}
  />
);

const SocialIcons = styled.div`
  display: flex;

  img {
    margin: 0 8px;
    width: 24px;
    height: 24px;
    filter: invert(${(props) => (props.theme.invertImages ? 1 : 0)});
    transition: filter 0.2s ease-out;
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.color.primary};
  padding: 32px 0;
`;

const Copyright = styled.div`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.font.regular};
  transition: color 0.2s ease-out;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const Art = styled.figure`
  display: flex;
  justify-content: center;
  margin: 0;
  margin-top: 48px;
  filter: invert(${(props) => (props.theme.invertImages ? 1 : 0)});
  transition: filter 0.2s ease-out;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

const FooterText = styled.span`
  ${(p) => p.theme.font_size.xsmall};
`;

export default Footer;
