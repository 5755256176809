import { css } from 'styled-components';

const ActionStyles = css`
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-transition: background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    color 0.2s ease-in-out;
  font-family: ${(p) => p.theme.font.markdown};
  background-color: transparent;
  border: 0;
  border-radius: 5px;
  box-shadow: inset 0 0 0 2px ${(p) => p.theme.color.font.regular};
  color: ${(p) => p.theme.color.font.regular};
  cursor: pointer;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 600;
  height: 3.5em;
  letter-spacing: 0.25em;
  line-height: 3.5em;
  padding: 0 1.75em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
`;

export default ActionStyles;
