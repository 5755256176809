import styled from 'styled-components';

import { Container } from '@components/global';

export const Nav = styled.nav`
  padding: 16px 0;
  background-color: ${(props) => props.theme.color.primary};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;

    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 1em;
        text-align: center;

        > ${NavItem} {
          position: relative;
          opacity: 0;
          margin: 0;
          margin-top: 0.75em;
        }
      `};
  }
`;

export const NavItem = styled.li`
  margin: 0 0.75em;
  font-family: ${(props) => props.theme.font.secondary};
  ${({ mobile, theme }) =>
    mobile ? theme.font_size.larger : theme.font_size.small};

  a {
    text-decoration: none;
    opacity: 0.7;
    color: ${(props) => props.theme.color.font.regular};
    transition: color 0.2s ease-out, opacity 0.2s ease-out;
  }

  &.active {
    a {
      opacity: 1;
    }
  }
`;

export const MenuButton = styled.button`
  filter: invert(${(props) => (props.theme.invertImages ? 1 : 0)});
  transition: filter 0.2s ease-out, opacity 0.2s ease-out;

  ${({ open }) =>
    open &&
    `
      position: relative;
      z-index: 30;
    `}
`;

export const MobileMenu = styled.div`
  position: fixed;
  background: ${(p) => p.theme.color.primary};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ open }) =>
    open &&
    `
      opacity: 0.9;
      visibility: visible;
      height: 100%;
      z-index: 20;

      ${NavItem} {
        animation: fadeInRight 0.3s ease forwards;
        animation-delay: 0.35s;
        &:nth-of-type(2) {
          animation-delay: 0.4s;
        }
        &:nth-of-type(3) {
          animation-delay: 0.45s;
        }
        &:nth-of-type(4) {
          animation-delay: 0.5s;
        }
        &:nth-of-type(5) {
          animation-delay: 0.55s;
        }
        &:nth-of-type(6) {
          animation-delay: 0.6s;
        }
      }
    `}
`;

export const Brand = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.large};
`;

export const Mobile = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    display: block;
    margin-left: 0.75em;
  }

  ${(props) =>
    props.hide &&
    `
    display: block;

    @media (max-width: ${props.theme.screen.md}) {
      display: none;
      margin-left: 0.75em;
      filter: invert(${(props) => (props.theme.invertImages ? 1 : 0)});
      transition: filter .2s ease-out;
    }
  `}
`;
