import React from 'react';
import PropTypes from 'prop-types';
import useDarkMode from 'use-dark-mode';
import { ThemeProvider } from 'styled-components';

import Seo from '@common/SEO';

import { lightTheme, darkTheme } from '@styles/theme';
import GlobalStyles from '@styles/GlobalStyles';

const Layout = ({ title, children }) => {
  const { value } = useDarkMode();
  const theme = value ? darkTheme : lightTheme;
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  const themeBody = (
    <ThemeProvider theme={theme}>
      <>
        <Seo title={title} />
        <GlobalStyles />
        {children}
      </>
    </ThemeProvider>
  );

  if (!mounted) return <div style={{ visibility: 'hidden' }}>{themeBody}</div>;

  return themeBody;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
