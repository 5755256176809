import React from 'react';
import useDarkMode from 'use-dark-mode';
import styled from 'styled-components';

const DarkMode = (props) => {
  const darkMode = useDarkMode();

  return (
    <LightDark title="Toggle dark/light mode">
      <ToggleContainer>
        <Toggle
          type="checkbox"
          onChange={darkMode.toggle}
          checked={!darkMode.value}
        ></Toggle>
        <div></div>
      </ToggleContainer>
    </LightDark>
  );
};

const LightDark = styled.div`
  margin-left: auto;
  margin-right: 0.75em;
`;

const ToggleContainer = styled.label`
  cursor: pointer;
`;

const Toggle = styled.input`
    display: none;
    & + div {
        border-radius: 50%;
        width: 16px;
        height: 16px;
        position: relative;
        box-shadow: inset 5px -5px 0 0 ${(props) =>
          props.theme.color.font.regular};
        transform: scale(1);
        transition: all 0.45s ease;
        &:before {
            content: '';
            border-radius: inherit;
            position: absolute;
            right: -9px;
            top: -9px;
            height: 24px,
            width: 24px,
            transition: background .45s ease;
        }
        &:after {
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin: -3px 0 0 -3px;
            position: absolute;
            top: 50%;
            left: 50%;
            box-shadow: 0 -16px 0 ${(props) =>
              props.theme.color.font.regular}, 0 16px 0 ${(props) =>
  props.theme.color.font.regular}, 16px 0 0 ${(props) =>
  props.theme.color.font.regular}, -16px 0 0 ${(props) =>
  props.theme.color.font.regular}, 10px 10px 0 ${(props) =>
  props.theme.color.font.regular}, -10px 10px 0 ${(props) =>
  props.theme.color.font.regular}, 10px -10px 0 ${(props) =>
  props.theme.color.font.regular}, -10px -10px 0 ${(props) =>
  props.theme.color.font.regular};
            transform: scale(0);
            transition: transform .35s ease;
        }
    }
    &:checked + div {
        box-shadow: inset 32px -32px 0 0 ${(props) =>
          props.theme.color.font.regular};
        transform: scale(.5) rotate(0deg);
        transition: transform .3s ease .1s, box-shadow .2s ease 0s;
        &:before {
            background: ${(props) => props.theme.color.font.regular};
            transition: all 0.35s ease;
        }
        &:after {
            transform: scale(1.0);
            transition: all 0.35s ease;
        }
    }
`;

export default DarkMode;
