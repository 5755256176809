import React, { useState } from 'react';
import { setSessionPassword } from '@mkitio/gatsby-theme-password-protect/src/utils/utils';
import Form, { Input, Submit, Actions, ActionItem } from '@common/UI/Form';

const PasswordForm = () => {
  const [password, setPassword] = useState('');

  const onSubmit = (event) => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <Form onSubmit={onSubmit} margin={30} alignCenter>
      <div className="field half first">
        <Input
          id="password"
          name="password"
          type="password"
          height={45}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </div>

      <div className="field half first">
        <Actions className="actions">
          <ActionItem>
            <Submit type="submit" value="Enter" />
          </ActionItem>
        </Actions>
      </div>
    </Form>
  );
};

export default PasswordForm;
